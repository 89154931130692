<template>
  <a-drawer width="40%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form">
      <a-form-model-item label="单号" prop="orderNo">
        <a-input :disabled="readOnly" v-model="form.orderNo" placeholder="请输入账单流水号" />
      </a-form-model-item>
      <a-form-model-item label="用户账户" prop="userName">
        <a-input :disabled="readOnly" v-model="form.userName" placeholder="请输入用户账户" />
      </a-form-model-item>
      <a-form-model-item label="时间" prop="createTime">
        <a-input :disabled="readOnly" v-model="form.createTime" placeholder="时间" />
      </a-form-model-item>
      <a-form-model-item label="智能秤设备号" prop="deviceId">
        <a-input :disabled="readOnly" v-model="form.deviceId" placeholder="请输入所属驿站" />
      </a-form-model-item>
      <a-form-model-item label="登录智能秤的ID" prop="createBy">
        <a-input :disabled="readOnly" v-model="form.createBy" placeholder="请输入所属驿站" />
      </a-form-model-item>
      <a-form-model-item label="所属驿站" prop="stationName">
        <a-input :disabled="readOnly" v-model="form.stationName" placeholder="请输入所属驿站" />
      </a-form-model-item>
      <a-form-model-item label="账单总金额（元）" prop="totalAmount">
        <a-input :disabled="readOnly" v-model="form.totalAmount" placeholder="请输入账单总金额" />
      </a-form-model-item>
      <a-form-model-item v-if="form.subPrice" label="已凑整（元）" prop="subPrice">
        <a-input :disabled="readOnly" v-model="form.subPrice" placeholder="请输入账单总金额" />
      </a-form-model-item>
      <a-form-model-item v-if="form.subPrice" label="已凑整（元）" prop="subPrice">
        <a-input :disabled="readOnly" v-model="form.subPrice" placeholder="请输入账单总金额" />
      </a-form-model-item>
      <a-form-model-item label="结算方式" prop="settlementType">
        <a-input
          :disabled="readOnly"
          :value="selectDictLabel(settlementTypeOptions, form.settlementType)"
          placeholder="结算方式"
        />
        <!-- <a-select placeholder="请选择结算方式 1 微信转账(企业转账到零钱) 2 后续提现" v-model="form.settlementType">
          <a-select-option v-for="(item, index) in settlementTypeOptions" :key="index" :value="item.dictValue">{{
            item.dictLabel
          }}</a-select-option>
        </a-select> -->
      </a-form-model-item>
      <a-form-model-item label="状态" prop="orderSatus">
        <a-input :disabled="readOnly" :value="selectDictLabel(orderSatusOptions, form.orderSatus)" placeholder="状态" />
        <!-- <a-select placeholder="请选择状态" v-model="form.orderSatus" style="width: 100%" allow-clear>
          <a-select-option v-for="(item, index) in orderSatusOptions" :key="index" :value="item.dictValue">{{
            item.dictLabel
          }}</a-select-option>
        </a-select> -->
      </a-form-model-item>
      <a-form-model-item label="订单详情">
        <a-table :loading="loading" rowKey="id" :columns="columns" :data-source="list" :pagination="false">
          <span slot="operation" slot-scope="text, record">
            <a-divider type="vertical" />
            <a @click="handlePicture(record)"> <a-icon type="edit" />查看照片 </a>
          </span>
        </a-table>
      </a-form-model-item>
      <image-view ref="imgView" :image-url="billImageUrl" style="display: none" />
      <div class="bottom-control" v-if="!readOnly">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getRecoveryBill, addRecoveryBill, updateRecoveryBill } from '@/api/recyclable/recoveryBill'
import ImageView from '@/components/ImageView'
import { settlementTypeOptions, orderSatusOptions } from './options'
import { Decimal } from 'decimal.js'

export default {
  name: 'CreateForm',
  props: {},
  components: {
    ImageView
  },
  data() {
    return {
      loading: false,
      readOnly: true,
      formTitle: '',
      // 表单参数
      form: {
        orderNo: null,
        userId: null,
        intelligentScaleId: null,
        postStationId: null,
        totalAmount: null,
        postStationUserId: null,
        settlementType: null,
        orderSatus: null,
        createTime: null
      },
      settlementTypeOptions,
      orderSatusOptions,
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        orderNo: [
          {
            required: true,
            message: '账单流水号 唯一性，单号使用日期时间作为编号前面部分，最后4位系统流水生成不能为空',
            trigger: 'blur'
          }
        ],
        userId: [{ required: true, message: '所属用户ID不能为空', trigger: 'blur' }],
        intelligentScaleId: [{ required: true, message: '所属智能秤id不能为空', trigger: 'blur' }],
        postStationId: [{ required: true, message: '所属驿站id不能为空', trigger: 'blur' }],
        totalAmount: [{ required: true, message: '账单总金额不能为空', trigger: 'blur' }],
        postStationUserId: [{ required: true, message: '驿站工作人员id 智能秤app用户id不能为空', trigger: 'blur' }],
        settlementType: [
          { required: true, message: '结算方式 1 微信转账(企业转账到零钱) 2 后续提现不能为空', trigger: 'change' }
        ]
      },
      tipMap: {
        1: '驿站',
        2: '上门',
        3: '自主设置'
      },
      columns: [
        {
          title: '物品种类',
          dataIndex: 'categoryName',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            return record.categoryName + '-' + this.tipMap[this.form.recoveryMode]
          }
        },
        {
          title: '重量(KG)',
          dataIndex: 'weight',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '价格(元/KG)',
          dataIndex: 'price',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '去杂(元)',
          dataIndex: 'impurityAmount',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '总额',
          dataIndex: 'actualAmount',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '120px',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      list: [],
      billImageUrl: []
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        orderNo: null,
        userId: null,
        intelligentScaleId: null,
        postStationId: null,
        totalAmount: null,
        postStationUserId: null,
        settlementType: null,
        orderSatus: null,
        createTime: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getRecoveryBill(id).then(response => {
        this.form = response.data
        for (const key in this.form) {
          if (typeof this.form[key] === 'number') {
            this.form[key] = this.form[key] + ''
          }
        }
        this.form.subPrice = new Decimal(this.form.totalAmount).sub(new Decimal(this.form.originalAmount)).toNumber()
        this.list = this.form.detail
        this.open = true
        this.formTitle = '修改'
      })
    },
    handlePicture(record) {
      this.billImageUrl = [record.scaleImageUrl]
      if (record.scaleImage2Url) {
        this.billImageUrl.push(record.scaleImage2Url)
      }
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.imgView.$el.childNodes[0].childNodes[0].click()
        })
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateRecoveryBill(this.form).then(response => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addRecoveryBill(this.form).then(response => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

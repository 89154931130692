/*
 * @Auth: linjituan
 * @Date: 2022-05-23 17:07:40
 * @LastEditors: linjituan
 * @LastEditTime: 2022-06-10 18:21:03
 */
export const settlementTypeOptions = [
  { dictLabel: '微信转账', dictValue: '1' },
  { dictLabel: '后续提现', dictValue: '2' }
]

export const orderSatusOptions = [
  { dictLabel: '待扫描绑定', dictValue: '1' },
  { dictLabel: '待确认', dictValue: '2' },
  { dictLabel: '待付款', dictValue: '3' },
  { dictLabel: '已付款', dictValue: '4' },
  { dictLabel: '支付失败', dictValue: '5' },
  { dictLabel: '已作废', dictValue: '6' }
]

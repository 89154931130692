var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "40%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "单号", prop: "orderNo" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: _vm.readOnly,
                  placeholder: "请输入账单流水号",
                },
                model: {
                  value: _vm.form.orderNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "orderNo", $$v)
                  },
                  expression: "form.orderNo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "用户账户", prop: "userName" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: _vm.readOnly,
                  placeholder: "请输入用户账户",
                },
                model: {
                  value: _vm.form.userName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "userName", $$v)
                  },
                  expression: "form.userName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "时间", prop: "createTime" } },
            [
              _c("a-input", {
                attrs: { disabled: _vm.readOnly, placeholder: "时间" },
                model: {
                  value: _vm.form.createTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "createTime", $$v)
                  },
                  expression: "form.createTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "智能秤设备号", prop: "deviceId" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: _vm.readOnly,
                  placeholder: "请输入所属驿站",
                },
                model: {
                  value: _vm.form.deviceId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deviceId", $$v)
                  },
                  expression: "form.deviceId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "登录智能秤的ID", prop: "createBy" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: _vm.readOnly,
                  placeholder: "请输入所属驿站",
                },
                model: {
                  value: _vm.form.createBy,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "createBy", $$v)
                  },
                  expression: "form.createBy",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "所属驿站", prop: "stationName" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: _vm.readOnly,
                  placeholder: "请输入所属驿站",
                },
                model: {
                  value: _vm.form.stationName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "stationName", $$v)
                  },
                  expression: "form.stationName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "账单总金额（元）", prop: "totalAmount" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: _vm.readOnly,
                  placeholder: "请输入账单总金额",
                },
                model: {
                  value: _vm.form.totalAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "totalAmount", $$v)
                  },
                  expression: "form.totalAmount",
                },
              }),
            ],
            1
          ),
          _vm.form.subPrice
            ? _c(
                "a-form-model-item",
                { attrs: { label: "已凑整（元）", prop: "subPrice" } },
                [
                  _c("a-input", {
                    attrs: {
                      disabled: _vm.readOnly,
                      placeholder: "请输入账单总金额",
                    },
                    model: {
                      value: _vm.form.subPrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "subPrice", $$v)
                      },
                      expression: "form.subPrice",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.subPrice
            ? _c(
                "a-form-model-item",
                { attrs: { label: "已凑整（元）", prop: "subPrice" } },
                [
                  _c("a-input", {
                    attrs: {
                      disabled: _vm.readOnly,
                      placeholder: "请输入账单总金额",
                    },
                    model: {
                      value: _vm.form.subPrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "subPrice", $$v)
                      },
                      expression: "form.subPrice",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "结算方式", prop: "settlementType" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: _vm.readOnly,
                  value: _vm.selectDictLabel(
                    _vm.settlementTypeOptions,
                    _vm.form.settlementType
                  ),
                  placeholder: "结算方式",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "状态", prop: "orderSatus" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: _vm.readOnly,
                  value: _vm.selectDictLabel(
                    _vm.orderSatusOptions,
                    _vm.form.orderSatus
                  ),
                  placeholder: "状态",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "订单详情" } },
            [
              _c("a-table", {
                attrs: {
                  loading: _vm.loading,
                  rowKey: "id",
                  columns: _vm.columns,
                  "data-source": _vm.list,
                  pagination: false,
                },
                scopedSlots: _vm._u([
                  {
                    key: "operation",
                    fn: function (text, record) {
                      return _c(
                        "span",
                        {},
                        [
                          _c("a-divider", { attrs: { type: "vertical" } }),
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handlePicture(record)
                                },
                              },
                            },
                            [
                              _c("a-icon", { attrs: { type: "edit" } }),
                              _vm._v("查看照片 "),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("image-view", {
            ref: "imgView",
            staticStyle: { display: "none" },
            attrs: { "image-url": _vm.billImageUrl },
          }),
          !_vm.readOnly
            ? _c(
                "div",
                { staticClass: "bottom-control" },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v(" 保存 ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "dashed" },
                          on: { click: _vm.cancel },
                        },
                        [_vm._v(" 取消 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }